import React, { Fragment, useState, useCallback } from "react";
import moment from "moment";
import * as GoogleAnalytics from "../../lib/google-analytics";
import MobilekeyMenual from "./MobilekeyMenual";
import CheckOut from "./CheckOut";

const BookingList = ({ bookingItem, checkOut, history }) => {
  const [isOpenModalMobilekeyMenual, setIsOpenModalMobilekeyMenual] =
    useState(false);
  const [isOpenModalCheckOut, setIsOpenModalCheckOut] = useState(false);

  const openModalMobilekeyMenual = useCallback(() => {
    GoogleAnalytics.customEvent({
      category: "button_click",
      action: "button_click_mobilekey_menual",
    });
    setIsOpenModalMobilekeyMenual(true);
  }, []);

  const closeModalMobilekeyMenual = useCallback(() => {
    setIsOpenModalMobilekeyMenual(false);
  }, []);

  const openModalCheckout = useCallback(() => {
    GoogleAnalytics.customEvent({
      category: "button_click",
      action: "button_click_checkout_request",
    });
    setIsOpenModalCheckOut(true);
  }, []);

  const closeModalCheckOut = useCallback(() => {
    setIsOpenModalCheckOut(false);
  }, []);

  const roomNoFormatter = (roomNo) => {
    return roomNo ? parseInt(roomNo.slice(roomNo.length - 4)) : roomNo;
  };

  const dayFormatter = (day) => {
    return (
      {
        Sun: "일",
        Mon: "월",
        Tue: "화",
        Wed: "수",
        Thu: "목",
        Fri: "금",
        Sat: "토",
      }[day] || day
    );
  };

  const setBookingDate = (date) => {
    return `${moment(date).format("YYYY.MM.DD")} (${dayFormatter(
      moment(date).format("ddd")
    )})`;
  };

  const setBookingTime = (time) => {
    return `${time.slice(0, 2)}:${time.slice(2, 4)}`;
  };

  return (
    <Fragment>
      <div className="checked-in-background-container">
        <div className="opacity-background">
          <span className="welcome-message">
            {bookingItem.inhsGestName} 고객님,
            <br />
            환영합니다.
          </span>
          <div className="booking-id-container">
            <span className="booking-id-label">예약번호</span>
            <span className="booking-id-value">
              {bookingItem.reservationNo}
            </span>
          </div>
        </div>
      </div>
      <div className="checked-in-booking-card-container">
        <span className="hotel-name">{bookingItem.propertyName}</span>
        <span className="room-name">{roomNoFormatter(bookingItem.roomNo)}</span>
        <div className="horizontal-line mt-15 mb-20"></div>
        <div className="userkey-container">
          <div className="userkey-item">
            <span className="userkey-item-label">객실 비밀번호</span>
            <span className="userkey-item-value">
              {bookingItem.entranceNo || "-"}
            </span>
          </div>
          <div className="userkey-item">
            <span className="userkey-item-label">
              모바일키
              <img
                className="tip-icon ml-2 mb-4"
                src="../../assets/images/question-mark.png"
                alt=""
                onClick={openModalMobilekeyMenual}
              />
            </span>
            <span className="userkey-item-value">
              {bookingItem.mobileRoomKey || "-"}
            </span>
          </div>
        </div>
        <div className="checked-in-booking-period-container">
          <img
            className="period-icon mt-2"
            src="../../assets/images/period.png"
            alt=""
          />
          <div className="period-item-container">
            <div className="period-item mb-8">
              <span className="period-item-label">체크인</span>
              <span className="period-item-value">
                {bookingItem.arrvDate
                  ? `${setBookingDate(bookingItem.arrvDate)} ${
                      bookingItem.arrvPlanTime &&
                      bookingItem.arrvPlanTime.length === 4
                        ? setBookingTime(bookingItem.arrvPlanTime)
                        : "15:00"
                    }`
                  : "-"}
              </span>
            </div>
            <div className="period-item">
              <span className="period-item-label">체크아웃</span>
              <span className="period-item-value">
                {bookingItem.deptDate
                  ? `${setBookingDate(bookingItem.deptDate)} ${
                      bookingItem.deptPlanTime &&
                      bookingItem.deptPlanTime.length === 4
                        ? setBookingTime(bookingItem.deptPlanTime)
                        : "11:00"
                    }`
                  : "-"}
              </span>
            </div>
          </div>
        </div>
        <div className="check-out-button-container">
          <span className="check-out-button" onClick={openModalCheckout}>
            비대면 체크아웃 하기
          </span>
        </div>
        <div className="horizontal-line mt-15 mb-30"></div>
        <div className="checked-in-booking-room-notice">
          <pre className="fw-default ws-pl fw-300">
            에메랄드 빛 함덕 해변을 품은 제주 유탑 유블레스 호텔에 방문해주셔서
            감사드리며, 셀프체크인 이용 안내드립니다.
            <br />
            {`
* 객실 내 부착되어있는 카드키는 객실 카드키가 아닌 전원 공급 전용 카드입니다. 본 카드키로는 문이 열리지 않습니다.

1. 셀프 체크인으로 발행된 비밀번호는 체크인 15:00, 체크아웃 11:00 해당기간까지 사용 가능하며, 이후에는 도어락 사용이 불가능 하오니 유의하시길 바랍니다.

2. 셀프 체크인을 한 경우 잔여 결제금이 없는 때에만 셀프 체크아웃 가능합니다.

3. 셀프 체크인을 한 경우 부대업장 및 추가 사용 요금 후불 결제는 불가능하며, 선결제만 가능합니다.

4. 연박, 레이트 체크아웃을 요청 시 프런트로 연락 부탁드립니다.`}
            <br />
            <br />
            <a href="https://litt.ly/ublessjeju">호텔 이용 관련 안내</a>
            <br />
            <br />
            <span className="fw-500">문의 064-780-9300</span>
            <br />
            <br />
            <span className="fw-500">내선 - 프런트 0번</span>
          </pre>
        </div>
      </div>
      <MobilekeyMenual
        isOpen={isOpenModalMobilekeyMenual}
        onClose={closeModalMobilekeyMenual}
      />
      <CheckOut
        isOpen={isOpenModalCheckOut}
        onClose={closeModalCheckOut}
        checkOut={checkOut}
      />
    </Fragment>
  );
};

export default BookingList;

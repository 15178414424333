import React, { Fragment, useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as GoogleAnalytics from '../lib/google-analytics';
import _ from 'lodash';
// import moment from 'moment';
import queryString from 'query-string';
import MainLayout from '../components/layouts/MainLayout';
import InitialBooking from '../components/views/BookingList';
import CheckedInBooking from '../components/views/CheckedInBooking';
import ModalError from '../components/ModalError';
import Spinner from '../components/Spinner';
import { bookingListAction } from '../stores/actions';
import * as api from '../api/';
import { logDataApi } from '../lib/logDataApi';

const InitialBookingContainer = ({
  history,
  match,
}) => {
  // const { folioNo } = history.location.state ? history.location.state : {};
  const systemId = process.env.REACT_APP_SYSTEM_ID;
  const companyId = process.env.REACT_APP_COMPANY_ID;
  const bsnsCode = process.env.REACT_APP_BSNS_CODE;
  const propertyNo = process.env.REACT_APP_PROPERTY_NO;
  const [ isLoading, setIsLoading ] = useState(false);
  const urlQueryStringParams = queryString.parse(history.location.search);
  const [ isOpenModalError, setIsOpenModalError ] = useState(false);
  const [ modalErrorMessage, setModalErrorMessage ] = useState('');
  const [ modalErrorSubMessage, setModalErrorSubMessage ] = useState('');
  const { queryStringParams, bookingItem, roomInfo } = useSelector(state => state.bookingList);
  const dispatch = useDispatch();

  const openModalError = useCallback(() => {
    setIsOpenModalError(true);
  }, []);

  const closeModalError = useCallback(() => {
    setIsOpenModalError(false);
  }, []);

  const errorMessageByBookingStatusFormatter = (status) => {
    return {
      RW: '예약 대기 상태 입니다.',
      RC: '취소된 예약 입니다.',
      RN: '예약 노쇼 상태 입니다.',
      CO: '체크아웃된 예약 입니다.',
      CX: '투숙 취소 상태 입니다.',
      RX: '삭제된 예약 입니다.',
      CH: '퇴실 보류 상태 입니다.',
    }[status] || '예약 상태를 확인 해주세요.';
  };

  const listBooking = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data: response } = await api.listBooking(!_.isEmpty(urlQueryStringParams) ? urlQueryStringParams : queryStringParams);
      if (response.userStatus.code !== 2000) {
        setModalErrorMessage('예약 조회에 실패 하였습니다.');
        throw new Error(`${response.userStatus.code}, ${response.userStatus.message}`);
      }
      if (response.resultData) {
        //Log 전송 여부 확인
        if (urlQueryStringParams.rsvnNo) logDataApi({rsvnNo: urlQueryStringParams.rsvnNo, progress: '0', ref: 'Y'});

        dispatch(bookingListAction.setBookingItem(response.resultData));
        if (!['RR', 'CI'].includes(response.resultData.status)) {
          setModalErrorMessage(errorMessageByBookingStatusFormatter(response.resultData.status));
          setModalErrorSubMessage('');
          openModalError();
        }
        else if (response.resultData.status === 'CI') await selectCheckedInBooking({
          folioNo: response.resultData.folioNo,
        });
      }
      else {
        dispatch(bookingListAction.setBookingItem({}));
        dispatch(bookingListAction.setRoomInfo({}));
      }
    } catch (error) {
      //Log 전송 여부 확인
      if(urlQueryStringParams.rsvnNo) logDataApi({rsvnNo: urlQueryStringParams.rsvnNo, progress: '0', ref: 'N'});

      dispatch(bookingListAction.setBookingItem({}));
      dispatch(bookingListAction.setRoomInfo({}));
      setModalErrorSubMessage(error.message);
      openModalError();
    } finally {
      setIsLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, queryStringParams, urlQueryStringParams]);

  const selectCheckedInBooking = useCallback(async ({folioNo}) => {
    try {
      const { data: response } = await api.selectCheckedInBooking({folioNo});
      if (response.userStatus.code !== 2000) {
        setModalErrorMessage('투숙 조회에 실패 하였습니다.');
        throw new Error(`${response.userStatus.code}, ${response.userStatus.message}`);
      }
      if (response.resultData && response.resultData.length) dispatch(bookingListAction.setRoomInfo(response.resultData[0]));
    } catch (error) {
      dispatch(bookingListAction.setRoomInfo({}));
      setModalErrorSubMessage(error.message);
      openModalError();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkOut = async () => {
    try {
      if(bookingItem.reservationNo) logDataApi({rsvnNo: bookingItem.reservationNo, progress: '5', ref: 'Y'});
      window.location = `https://wingsays.sanhait.com/ays/IMGATE/${systemId}/Check_Out?company=${encodeURI(btoa(companyId))}&folioNo=${encodeURI(`${btoa(bookingItem.folioNo)}`)}&reservationNo=${encodeURI(`${btoa(bookingItem.reservationNo)}#`)}bsnsCode=${encodeURI(btoa(bsnsCode))}&propertyNo=${encodeURI(btoa(propertyNo))}`;
    } catch (error) {
      setModalErrorSubMessage(error.message);
      openModalError();
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!_.isEmpty(urlQueryStringParams)) dispatch(bookingListAction.setBookingQueryStringParams(urlQueryStringParams));
    listBooking();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (bookingItem.reservationNo && bookingItem.status === 'RR') GoogleAnalytics.customEvent({
      category: 'page_view',
      action: 'page_view_booking_info',
      label: `${bookingItem.reservationNo}_${bookingItem.status}_${bookingItem.reservationName}`,
    });
    else if (bookingItem.reservationNo && bookingItem.status === 'CI') GoogleAnalytics.customEvent({
      category: 'page_view',
      action: 'page_view_stay_info',
      label: `${bookingItem.reservationNo}_${bookingItem.status}_${bookingItem.reservationName}`,
    });
  }, [bookingItem.reservationName, bookingItem.reservationNo, bookingItem.status]);

  return (
    <Fragment>
      {!_.isEmpty(bookingItem) && bookingItem.status === 'CI' ? (
        <MainLayout
          history={history}
          customStyle={{position: 'relative', margin: 0}}
          ContentBody={(
            <CheckedInBooking
              bookingItem={{...bookingItem, ...roomInfo}}
              checkOut={checkOut}
              history={history}
            />
          )}
        />
      ) : (
        <MainLayout
          history={history}
          ContentBody={(
            <InitialBooking
              bookingItem={bookingItem}
              listBooking={listBooking}
              setModalErrorMessage={setModalErrorMessage}
              setModalErrorSubMessage={setModalErrorSubMessage}
              openModalError={openModalError}
              history={history}
            />
          )}
        />
      )}
      <ModalError
        isOpen={isOpenModalError}
        message={modalErrorMessage}
        subMessage={modalErrorSubMessage}
        onClose={closeModalError}
      />
      <Spinner
        isLoading={isLoading}
      />
    </Fragment>
  );
};

export default InitialBookingContainer;